import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { Card, CardContent, FormControl, Grid, MenuItem, Select } from "@material-ui/core";

import { listAllSellingNfts, resetSellingNfts, resetLoadingText } from "store/actions";
// import { convertToSOL } from "helpers";
import { history, isAlgo } from "utils";
import CustomTextButton from "components/CustomButton/CustomTextButton";
import { lockOrUnlockNft } from "store/actions";

const styles = {
    floating: {
        position: "fixed",
        bottom: "40px",
        right: "80px",
        borderRadius: "20px",
        backgroundColor: "#262734",
        textAlign: "center",
        padding: "15px",
    },
    options: {
        marginLeft: "70px", 
        width: "100%"
    },
    formControl: { 
        maxWidth: "200px", 
        minWidth: "150px" 
    },
    btns: {
        backgroundColor: "#262734", 
        padding:"7px", 
        borderRadius:"8px",
    },
    empty: {
        color: "white", 
        fontWeight: "normal",
        padding: "100px 0"
    },
    listOuter: { 
        padding: "1em", 
        marginLeft: "40px", 
        marginBottom: "100px" 
    },
    name: {
        height: "40px",
        display: "grid",
        placeItems: "center",
        textTransform: "uppercase",
        textAlign: "center",
    }
}

function Home() {
    const dispatch = useDispatch();
    const [selection, setSelection] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [data, setData] = useState([]);
    const [type, setType] = useState('all');

    const { sellingNfts, viewMoreButton } = useSelector(({ nfts: { list, showViewMore } }) => ({
        sellingNfts: list,
        viewMoreButton: showViewMore,
    }));

    useEffect(() => {
        dispatch(resetSellingNfts());
        dispatch(resetLoadingText());
        fetchMoreData();
    }, [type]);

    const fetchMoreData = () => {
        dispatch(listAllSellingNfts({ type: type }));
    };

    const handleSelection = (e) => {
        let arrCopy = data;
        if (data.filter((j) => j === e?.nft_id?.nft_id).length) {
            arrCopy = data.filter((j) => j !== e?.nft_id?.nft_id);
            setTotalPrice(totalPrice - e?.amount);
        } else {
            arrCopy.push(e?.nft_id?.nft_id);
            setTotalPrice(totalPrice + e?.amount);
        }
        dispatch(lockOrUnlockNft({ id: e?._id }));
        setData(arrCopy);
    };
    return (
        <>
            <div style={styles.options}>
                <Grid container spacing={2}>
                <Grid item >
                        <FormControl
                            variant="outlined"
                            size="small"
                            style={styles.formControl}
                        >
                            <Select
                                value={type}
                                onChange={(event) => {
                                    setType(event.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="all"><em>All</em></MenuItem>
                                <MenuItem value="rice">Rice</MenuItem>
                                <MenuItem value="wheat">Wheat</MenuItem>
                                <MenuItem value="cashew">Cashew</MenuItem>
                                <MenuItem value="peanut">Peanut</MenuItem>
                                <MenuItem value="cocoa">Cocoa</MenuItem>
                                <MenuItem value="coffee">Coffee</MenuItem>
                                <MenuItem value="fish">Fish</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {sellingNfts.length > 1 ?
                    <Grid item>
                        <div style={styles.btns}>
                        <CustomTextButton
                            title="Buy single"
                            size="small"
                            disabled={selection === false}
                            onClick={() => setSelection(false)}
                        /> &nbsp;
                        <CustomTextButton
                            title="Buy multiple"
                            size="small"
                            disabled={selection === true}
                            onClick={() => setSelection(true)}
                        />
                        </div>
                    </Grid>: null}
                </Grid>
            </div>
            {sellingNfts.length ? (
                <Grid
                    container
                    justify="flex-start"
                    spacing={4}
                    style={styles.listOuter}
                >
                    {sellingNfts.map((e, i) => {
                        return (
                            <Grid item xs={10} sm={5} lg={3} md={4} key={i} style={{ display: "flex" }}>
                                <Card style={{ width: "100%" }}>
                                    <CardContent>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <div style={styles.name}>
                                                    <h4> {e?.nft_id?.name}</h4>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <img width="150" height="150" src={e?.nft_id?.art_url} alt="" />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" style={{ paddingTop: "5px" }}>
                                            <Grid item>
                                                {/* <h3>{e?.amount} INR</h3> */}
                                            </Grid>
                                        </Grid>
                                        {selection ? (
                                            <Grid container justify="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        title={
                                                            data.filter((elemnt) => elemnt === e.nft_id.nft_id).length
                                                                ? "selected"
                                                                : "select"
                                                        }
                                                        color="primary"
                                                        onClick={() => handleSelection(e)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container justify="center">
                                                <Grid item>
                                                    <CustomTextButton
                                                        title="View Details"
                                                        color="primary"
                                                        onClick={() =>
                                                            history.push(`/auth/buy-artwork/${e?.nft_id?.nft_id}${isAlgo() ? "?version=algo" : ""}`)
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justify="center" spacing={3}>
                    <Grid>
                        <h2 style={styles.empty}>No items available</h2>
                    </Grid>
                </Grid>
            )}
            {viewMoreButton ? (
                <Grid container justify="center" spacing={2} style={{ paddingTop: "10px" }}>
                    <Grid item>
                        <CustomTextButton title="Show More" onClick={() => fetchMoreData()} />
                    </Grid>
                </Grid>
            ) : null}
            {selection ? (
                <div style={styles.floating}>
                    <b>Total price: {totalPrice} INR &nbsp;</b>
                    <CustomTextButton
                        marginTop="10px"
                        title="Buy now"
                        size="small"
                        disabled={totalPrice < 1}
                        onClick={() => history.push({ pathname: `/auth/buy-multiple-nfts${isAlgo() ? "?version=algo" : ""}`, datas: { data, totalPrice } })}
                    />
                </div>
            ) : null}
        </>
    );
}

export default Home;
