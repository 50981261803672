import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import CustomModal from "components/CustomModal/CustomModal";
import BuyNowModalContent from "../buyNowModalContent";

import { listNftDetails, openModal } from "store/actions";
import { convertToSOL, getSolExplorerUrl } from "helpers";
import { isAlgo } from "utils";

const details = {
    card: {
        padding: "140px 10px 40px",
        margin: "100px 0px 150px",
        borderRadius: "8px",
        backgroundColor: "#1A1B28",
        boxShadow: "4.871px 10px 8px 0px rgba(0, 0, 0, 0.06)",
        position: "relative",
    },
    pic: {
        backgroundColor: "white",
        borderRadius: "8px",
        width: "200px",
        height: "200px",
        border: "5px solid #76fc4e",
        overflow: "hidden",
        margin: "0 auto",
        position: "absolute",
        top: "-100px",
        left: "0",
        right: "0",
    },
    text: {
        fontSize: "20px",
        color: "rgb(173, 173, 173)",
        lineHeight: "34px",
        position: "relative",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    label: {
        fontSize: "17px",
        fontWeight: "900",
    },
    chip: {
        backgroundColor: "#262734",
        borderRadius: "6px",
        textAlign: "right",
        padding: "5px 20px",
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "600",
    },
};

const BuyNftPage = () => {
    const dispatch = useDispatch();
    const { nftAddress } = useParams();

    const { nftDetails } = useSelector(({ nfts: { details } }) => ({
        nftDetails: details,
    }));

    useEffect(() => {
        dispatch(listNftDetails({ nftId: nftAddress }));
    }, []);

    return (
        <>
            <div style={{ width: "100%" }}>
                <Grid container justify="center">
                    <Grid item lg={8} md={8} sm={8} xs={10}>
                        <div style={details.card}>
                            <div style={details.pic}>
                                <img src={nftDetails?.art_url} alt="" style={{ width: "100%", height: "100%" }} />
                            </div>
                            <div style={details.text}>
                                <div style={{ textAlign: "center" }}>
                                    <h2>{nftDetails?.name}</h2>
                                    <h5>{nftDetails?.description}</h5>
                                </div>
                                <br />

                                <Grid container>
                                    <Grid item lg={4}>
                                        <label style={details.label}>Created By</label>
                                    </Grid>
                                    <Grid item lg={8} xs={12} style={details.chip}>
                                        {nftDetails?.creator_id?.name ?? "-"}
                                    </Grid>
                                </Grid>

                                {/* <Grid container>
                                    <Grid item lg={4}>
                                        <label style={details.label}>Royalties </label>
                                    </Grid>
                                    <Grid item lg={8} xs={12} style={details.chip}>
                                        {nftDetails?.royalty_percentage ?? "-"} %
                                    </Grid>
                                </Grid>

                                {nftDetails?.is_for_sale ? (
                                    <Grid container>
                                        <Grid item lg={4}>
                                            <label style={details.label}>Price </label>
                                        </Grid>
                                        <Grid item lg={8} xs={12} style={details.chip}>
                                            {nftDetails?.selling_price} INR
                                        </Grid>
                                    </Grid>
                                ) : null} */}
                            </div>

                            <div style={{ margin: "27px" }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={isAlgo() ? 12 : 6} xs={isAlgo() ? 12 : 6}>
                                        <CustomTextButton
                                            title="Details"
                                            size="small"
                                            variant="outlined"
                                            style={{ color: "rgb(173, 173, 173)", width: "100%", height: "100%" }}
                                            onClick={() => window.open(`https://renai.io/details/${nftDetails?._id}${isAlgo() ? "?version=algo" : ""}`)}
                                        />
                                    </Grid>
                                    {isAlgo() ? null : (
                                    <Grid item lg={6} xs={6}>
                                        <CustomTextButton
                                            title="View On Solana"
                                            size="small"
                                            variant="outlined"
                                            style={{ width: "100%", color: "rgb(173, 173, 173)" }}
                                            onClick={() => window.open(getSolExplorerUrl(nftAddress))}
                                        />
                                    </Grid>
                                    )}

                                    <Grid item lg={12} xs={12}>
                                        <CustomTextButton
                                            title="Invest Now"
                                            style={{ width: "100%" }}
                                            disabled={!nftDetails?.is_for_sale}
                                            onClick={() =>
                                                dispatch(
                                                    openModal(
                                                        <BuyNowModalContent nftId={nftAddress} nftDetails={nftDetails} />
                                                    )
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <CustomModal title="Create Contract" />
        </>
    );
};

export default BuyNftPage;
