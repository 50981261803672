import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Grid } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import CustomModal from "components/CustomModal/CustomModal";
import BuyNowModalContent from "../buyNowModalContent";

import { openModal } from "store/actions";
import { history, isAlgo } from "utils";

const details = {
    card: {
        padding: "40px 10px 40px",
        margin: "100px 0px 150px",
        borderRadius: "8px",
        backgroundColor: "#1A1B28",
        boxShadow: "4.871px 10px 8px 0px rgba(0, 0, 0, 0.06)",
        position: "relative",
    },
    text: {
        fontSize: "20px",
        color: "rgb(173, 173, 173)",
        lineHeight: "34px",
        position: "relative",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    label: {
        fontSize: "17px",
        fontWeight: "900",
    },
    chip: {
        backgroundColor: "#262734",
        borderRadius: "6px",
        textAlign: "right",
        padding: "5px 20px",
        marginBottom: "10px",
        fontSize: "16px",
        fontWeight: "600",
        wordWrap: "break-word",
    },
    box: {
        width: "100%",
        maxHeight: "150px",
        overflow: "hidden",
        overflowY: "scroll",
        border: "1px solid",
        padding: "10px",
        borderRadius: "8px",
    },
};

const BuyMultipleNfts = () => {
    const dispatch = useDispatch();
    const { datas } = useLocation();

    useEffect(() => {
        if (!datas) {
            history.push(`/auth/listings${isAlgo() ? "?version=algo" : ""}`);
        }
    }, []);

    return (
        <>
            <div style={{ width: "100%" }}>
                <Grid container justify="center">
                    <Grid item lg={8} md={8} sm={8} xs={10}>
                        <div style={details.card}>
                            <div style={details.text}>
                                <Grid container>
                                    <Grid item lg={4}>
                                        <label style={details.label}>selected NFTS</label>
                                    </Grid>
                                    <div style={details.box}>
                                        {datas?.data.map((e, i) => {
                                            return (
                                                <Grid item lg={12} xs={12} style={details.chip} key={i}>
                                                    {e}
                                                </Grid>
                                            );
                                        })}
                                    </div>
                                </Grid>

                                <Grid container>
                                    <Grid item lg={4}>
                                        <label style={details.label}>Total Price</label>
                                    </Grid>
                                    <Grid item lg={12} xs={12} style={details.chip}>
                                        {datas?.totalPrice} INR
                                    </Grid>
                                </Grid>
                            </div>

                            <div style={{ margin: "27px" }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} xs={12}>
                                        <CustomTextButton
                                            title="Buy Now"
                                            style={{ width: "100%" }}
                                            // disabled={!nftDetails?.is_for_sale}
                                            onClick={() =>
                                                dispatch(
                                                    openModal(
                                                        <BuyNowModalContent
                                                            nftId={datas?.data}
                                                            nftDetails={{ selling_price: datas?.totalPrice }}
                                                            isBulk={true}
                                                        />
                                                    )
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <CustomModal title="Create Contract" />
        </>
    );
};

export default BuyMultipleNfts;
