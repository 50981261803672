import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";

// import componentStyles from "assets/theme/views/auth/login.js";
import { listAllCreatorNfts, resetAllCreatorNfts } from "store/actions";
import { history, isAlgo } from "utils";
import CustomTextButton from "components/CustomButton/CustomTextButton";

// const useStyles = makeStyles(componentStyles);

const ArtistProfile = () => {
    // const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { creatorAddress } = useParams();

    const { creatorArts, creatorDetails, viewMoreButton } = useSelector(
        ({
            creators: {
                details,
                arts: { list, showViewMore },
            },
        }) => ({
            creatorDetails: details,
            creatorArts: list,
            viewMoreButton: showViewMore,
        })
    );

    useEffect(() => {
        dispatch(resetAllCreatorNfts());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllCreatorNfts({ creatorId: creatorAddress }));
    };

    return (
        <div style={{width:"100%"}}>
            <div style={{ marginBottom: "30px", display: "flex", justifyContent: "center" }}>
                <h1 style={{ textTransform: "uppercase", color: "white", fontWeight: "normal" }}>{creatorDetails?.name}</h1>
            </div>
            <div style={{ padding: "20px" }}>
                {creatorArts.length === 0 ?
                    <Grid style={{ padding: "50px", textAlign: "center" }}>
                        <h3 style={{ color: "white" }}> No artworks created </h3>
                    </Grid>
                    :
                    <h2>ART CREATED</h2>}
                <Grid container spacing={3} style={{ padding: "1em" }}>
                    {creatorArts.map((e, i) => {
                        return (
                            <Grid item xs={12} sm={12} lg={3} md={6} key={i}>
                                <Card
                                    // classes={{ root: classes.cardRoot }}
                                    onClick={() => history.push(`/auth/artwork/${e?.nft_id}${isAlgo() ? "?version=algo" : ""}`)}
                                    style={{ width: "100%", height: "100%",padding:"20px" }}
                                >
                                    <CardContent >
                                        <Grid container justify="center">
                                            <Grid item>
                                                <img height="160px" src={e?.art_url} alt="" />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <h3 style={{ textTransform: "uppercase", fontWeight: "normal" }}>
                                                    {e?.name}
                                                </h3>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container justify="center" style={{ paddingTop: "10px" }} spacing={3}>
                    {viewMoreButton ? <CustomTextButton title="Show More" onClick={() => fetchMoreData()} /> : null}
                </Grid>
            </div>
        </div>
    );
};

export default ArtistProfile;