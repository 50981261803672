export const FETCH_ALL_CREATORS = `query ($pageNumber: Int, $pageSize: Int) {
  list_all_creators(page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      name
      email
      img
    }
    hasMore
  }
}

  `;

export const FETCH_ALL_CREATOR_NFTS = `query ($creatorId: String!, $pageNumber: Int, $pageSize: Int) {
  list_all_creator_nfts(creator_id: $creatorId, page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      creator {
        _id
        name
        email
        img
      }
      arts {
        _id
        name
        art_url
        nft_id
      }
    }
    hasMore
  }
}

  `;

export const CHECK_FOR_CREATOR = `query ($userId: String!) {
  check_for_creator(user_id: $userId) {
    status
  }
}
`;
