import React from "react";
import { Grid, Button, Card, CardContent } from "@material-ui/core";

function ContactUs() {
  return (
    <>
      <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
        <Grid item>
          <h3 style={{ color: "white" }}>Join the beta list</h3>
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={3}>
        <Grid item>
          <Card style={{ maxWidth: "28rem", textAlign: "center" }}>
              <CardContent>
                <h3>
                  Join our discord community... <br />
                  Initial 5000 members will be rewarded.
                </h3>
              </CardContent>
              <a href="https://discord.gg/kmJsJqmuDD">
                <Button variant="contained">
                  JOIN DISCORD{" "}
                  <i title="Discord" className="fab fa-discord"></i>
                </Button>
              </a>
            <br/>
          </Card>
        </Grid>
      </Grid>
      
    </>
  );
}

export default ContactUs;
