import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";

import componentStyles from "assets/theme/views/auth/login.js";
import { history, isAlgo } from "utils";

const useStyles = makeStyles(componentStyles);

const UserNftContent = ({ data }) => {
    const classes = useStyles();

    return (
        <>
            {data.length ? (
                data.map((e, i) => {
                    return (
                        <Grid item xs={6} lg={3} md={7} key={i} style={{ display: "flex" }}>
                            <Card
                                classes={{ root: classes.cardRoot }}
                                onClick={() => history.push(`/auth/artwork/${e?.nft_id}${isAlgo() ? "?version=algo" : ""}`)}
                                style={{ width: "100%", height: "100%" }}
                            >
                                <CardContent>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <h3 style={{ textTransform: "uppercase", fontWeight: "normal" }}>{e?.name}</h3>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="center">
                                        <Grid item>
                                            <img width="100%" height="100%" src={e?.art_url} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })
            ) : (
                <Grid item>
                    <h3 style={{ color: "whitesmoke" }}>No Collections</h3>
                </Grid>
            )}
        </>
    );
};

export default UserNftContent;
