import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";

import componentStyles from "assets/theme/views/auth/login.js";
import { listAllCreators, resetAllCreators } from "store/actions";
import { history, isAlgo } from "utils";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const useStyles = makeStyles(componentStyles);

function Artists() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { data, viewMoreButton } = useSelector(({ creators: { list, showViewMore } }) => ({
        data: list,
        viewMoreButton: showViewMore,
    }));

    useEffect(() => {
        dispatch(resetAllCreators());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllCreators());
    };

    return (
        <div style={{width:"100%"}}>
            {data.length ? (
                <Grid container justify="flex-start" spacing={4} style={{ padding: "1em", justifyContent: "center" }}>
                    {data.map((e, i) => {
                        return (
                            <Grid item key={i} style={{ display: "flex" }}>
                                <Card
                                    classes={{ root: classes.cardRoot }}
                                    onClick={() => history.push(`/auth/creator/${e._id}${isAlgo() ? "?version=algo" : ""}`)}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <img
                                                    width="150"
                                                    height="150"
                                                    src={e.img ?? require("assets/img/user/undraw_profile_pic.png").default}
                                                    alt=""
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" style={{ paddingTop: "10px" }}>
                                            <Grid item>
                                                <h2 style={{ textTransform: "uppercase", fontWeight: "normal" }}>
                                                    {e.name}
                                                </h2>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
                    <Grid item>
                        <h2 style={{ color: "white", fontWeight: "normal" }}>No creators available</h2>
                    </Grid>
                </Grid>
            )}
            <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
                {viewMoreButton ? (
                    <Grid item>
                        <CustomTextButton title="Show More" onClick={() => fetchMoreData()} />
                    </Grid>
                ) : null}
            </Grid>
        </div>
    );
}

export default Artists;
