import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PublicKey } from "@solana/web3.js";
import * as Yup from "yup";
import axios from "axios";
import { CircularProgress, Backdrop, FilledInput } from "@material-ui/core";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import { toggleLoading, closeModal, purchaseMultipleNfts } from "store/actions";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { graphqlBaseUrl, RAZORPAY_KEY_ID } from "helpers";
import { history, isAlgo } from "utils";
import { algoBaseUrl } from "helpers";

const BuyNowModalContent = ({ nftId, nftDetails, isBulk = false }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector(({ loading }) => ({
        loading: loading,
    }));

    const validateSolAddress = (address) => {
        try {
            let pubkey = new PublicKey(address);
            let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
            return isSolana;
        } catch (error) {
            return false;
        }
    };

    const handleSubmit = async (destWalletId) => {
        try {
            dispatch(toggleLoading(true));
            let isSolAddress = validateSolAddress(destWalletId);
            if (!isSolAddress && !isAlgo()) {
                dispatch(toggleLoading(false));
                return toast.error("Please provide a valid solana wallet address");
            }
            dispatch(closeModal());

            const result = await axios.post(`${isAlgo() ? algoBaseUrl : graphqlBaseUrl}/razorpay/orders`, {
                amount: nftDetails.selling_price * 100,
                nftId: nftId,
                destWalletId: destWalletId,
            });

            const { amount, id: order_id, currency } = result.data;

            const options = {
                key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                image: nftDetails?.art_url,
                name: nftDetails?.name,
                description: "Buy NFT",
                order_id: order_id,
                handler: async function (response) {
                    try {
                        const data = {
                            orderCreationId: order_id,
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                            nftId: nftId,
                            destWalletId: destWalletId,
                        };

                        dispatch(toggleLoading(true));

                        if (!isBulk) {
                            await axios.post(`${isAlgo() ? algoBaseUrl : graphqlBaseUrl}/razorpay/success`, data);
                        } else {
                            dispatch(purchaseMultipleNfts(data));
                        }
                        toast.success("Transaction Success");
                        dispatch(toggleLoading(false));
                        history.push(`/auth/listings${isAlgo() ? "?version=algo" : ""}`);
                    } catch (err) {
                        dispatch(toggleLoading(false));
                        return toast.error("Payment Failed. Try again after sometime");
                    }
                },
                prefill: {
                    name: "",
                    email: "",
                    contact: "",
                },
                notes: {
                    address: "",
                },
                theme: {
                    color: "#76fc4e",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            paymentObject.on("payment.failed", function (response) {
                toast.error("Payment Failed. Try again after sometime");
            });
            dispatch(toggleLoading(false));
        } catch (err) {
            dispatch(toggleLoading(false));
            return toast.error(err?.response?.data?.message ?? "Failed to initaite order. Try again after sometime");
        }
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Formik
                initialValues={{
                    destWalletId: "",
                }}
                validationSchema={Yup.object().shape({
                    destWalletId: Yup.string().max(255).required("Wallet address is required"),
                })}
                onSubmit={(value) => {
                    handleSubmit(value.destWalletId);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form
                        onSubmit={() => {
                            handleSubmit(values);
                        }}
                    >
                        <div>
                            <br />
                            <label htmlFor="name" style={{ color: "white", fontSize: 15 }}>
                                Enter destination wallet address
                            </label>
                            <br />
                            &nbsp;
                            <FilledInput
                                key="destWalletId"
                                name="destWalletId"
                                autoComplete="off"
                                type="text"
                                onBlur={handleBlur}
                                placeholder="Destination wallet id"
                                onChange={handleChange}
                                value={values.destWalletId}
                                error={Boolean(touched.destWalletId && errors.destWalletId)}
                            />
                            {Boolean(touched.destWalletId && errors.destWalletId) ? (
                                <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 13 }}>
                                    {errors.destWalletId}
                                </div>
                            ) : (
                                ""
                            )}
                            <br />
                        </div>
                        <div>
                            <CustomTextButton
                                title="Proceed to pay"
                                style={{ width: "100%" }}
                                onClick={handleSubmit}
                                disabled={values.destWalletId === ""}
                                type="submit"
                            />
                        </div>
                        <br />
                        <div>
                            <strong>
                                If you don't have a wallet address{" "}
                                <a href={`https://renai.io/auth/signup${isAlgo() ? "?version=algo" : ""}`} target="_self">
                                    signup
                                </a>{" "}
                                to create one
                            </strong>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default BuyNowModalContent;
